'use strict'
const React = require('react')
const PropTypes = require('prop-types')
const connect = require('../../components/connect')
const styles = require('../../styles/package.css')
const {sparkline} = require('@fnando/sparkline')
const DownloadsIcon = require('../icons/downloads')

// Global declaration of data mapping
const mapData = rawData => rawData.map(({downloads, label}) => ({date: label, value: downloads}))

class Downloads extends React.PureComponent {
  constructor(props) {
    super(props)
    const data = mapData(props.data) // Map data in constructor
    this.state = {hoverData: null, selectedIndex: null, data}
    this.svgRef = React.createRef()
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  componentDidMount() {
    this.initSparkline()
  }

  componentDidUpdate(prevProps) {
    // If data prop changes, remap data and reinitialize the sparkline
    if (prevProps.data !== this.props.data) {
      const data = mapData(this.props.data)
      this.setState({data}, this.initSparkline)
    }
  }

  initSparkline() {
    const svg = this.svgRef.current
    const {data} = this.state

    try {
      sparkline(svg, data, {
        onmousemove: (ev, datapoint) => {
          this.setState({hoverData: datapoint, selectedIndex: datapoint.index})
        },
        onmouseout: () => this.setState({hoverData: null, selectedIndex: null}),
      })
    } catch (e) {
      console.error(e)
    }
  }

  handleFocus() {
    const {data} = this.state

    if (this.state.hoverData === null && data.length > 0) {
      const firstDataPoint = data[0]
      this.setState({hoverData: firstDataPoint, selectedIndex: 0}, () => {
        this.updateCursorAndSpot(0, firstDataPoint)
      })
    }
  }

  handleBlur() {
    this.setState({hoverData: null, selectedIndex: null}, () => {
      this.updateCursorAndSpot(null, null)
    })
  }

  handleKeyDown(event) {
    const {data} = this.state
    const {selectedIndex} = this.state

    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.preventDefault()

      let newIndex = selectedIndex !== null ? selectedIndex : event.key === 'ArrowLeft' ? data.length : -1

      if (event.key === 'ArrowLeft') {
        newIndex = newIndex - 1 >= 0 ? newIndex - 1 : data.length - 1
      } else if (event.key === 'ArrowRight') {
        newIndex = (newIndex + 1) % data.length
      }

      const newHoverData = data[newIndex]
      this.setState({hoverData: newHoverData, selectedIndex: newIndex}, () => {
        this.updateCursorAndSpot(newIndex, newHoverData)
      })
    }
  }

  updateCursorAndSpot(index, datapoint) {
    const svg = this.svgRef.current
    const svgWidth = parseFloat(svg.attributes.width.value)
    const svgHeight = parseFloat(svg.attributes.height.value)
    const spotRadius = 2 // Assuming a default spot radius, adjust if necessary
    const strokeWidth = parseFloat(svg.attributes['stroke-width'].value)

    let pointX = -1000
    let pointY = 0

    if (index != null && datapoint != null) {
      // Calculate x and y positions using the same logic as in sparkline.js
      const max = Math.max(...this.state.data.map(d => d.value))
      const width = svgWidth - spotRadius * 2
      const height = svgHeight - strokeWidth * 2 - spotRadius * 2
      const offset = width / (this.state.data.length - 1)

      pointX = index * offset + spotRadius
      pointY = height - (datapoint.value * height) / max + (strokeWidth + spotRadius)
    }

    // Update line position
    const cursorLine = svg.querySelector('.sparkline--cursor')
    cursorLine.setAttribute('x1', pointX)
    cursorLine.setAttribute('x2', pointX)

    // Update circle position
    const spotCircle = svg.querySelector('.sparkline--spot')
    spotCircle.setAttribute('cx', pointX)
    spotCircle.setAttribute('cy', pointY)
  }

  render() {
    const {data, hoverData} = this.state
    const lastDataPoint = data && data.length > 0 ? data[data.length - 1] : null
    return (
      <div
        className={`${styles.sidebarSection} w-100`}
        role="button"
        tabIndex={0}
        onKeyDown={this.handleKeyDown}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        aria-label="Showing weekly downloads"
      >
        <h3 className={`${styles.sidebarHeader} ${styles.downloads}`}>
          <DownloadsIcon />
          {hoverData ? hoverData.date : 'Weekly Downloads'}
        </h3>
        <div className={styles.sparklineContainer}>
          <svg
            className={styles.sparkline}
            ref={this.svgRef}
            width={200}
            height={40}
            strokeWidth="3"
            stroke="#8956FF"
            fill="rgba(137, 86, 255, .2)"
            aria-hidden={true}
          />
          <p className={styles.counter}>
            {hoverData && hoverData.value !== undefined
              ? hoverData.value.toLocaleString()
              : lastDataPoint && lastDataPoint.value !== undefined
                ? lastDataPoint.value.toLocaleString()
                : '0'}
          </p>
        </div>
      </div>
    )
  }
}

Downloads.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      downloads: PropTypes.number,
      label: PropTypes.string,
    }),
  ).isRequired,
}

Downloads.defaultProps = {
  data: [{downloads: 0, label: 'unknown'}],
}

module.exports = connect()(Downloads)
